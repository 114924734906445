import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import AOS from 'aos';
import 'aos/dist/aos.css';

/* JS */
import * as Common from '../../asset/js/Common';

const CosmeticType = props => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <ProductSec>
        <TypeList>
          <Inner>
            <Types>
              {props?.item?.map((v, i) => {
                if (v['textLoc'] == 'L') {
                  return (
                    <li
                      key={i}
                      style={{
                        background: 'url(' + v['productUrl'] + ') top 50% left 50% no-repeat',
                      }}
                    >
                      <ItemDesc>
                        <DescInner>
                          {!Common.isEmpty(v['tagName']) &&
                            <ItemUtil>
                              {
                                v['tagName'].split(',').map((item, index) => (
                                  <Util>
                                    <span>{item}</span>
                                  </Util>
                                ))
                              }
                            </ItemUtil>
                          }
                          <ItemInfo>
                            {/* DESC */}
                            <ItemMain>{v['description']}</ItemMain>
                            <ItemKR>{v['productName']}</ItemKR>
                            <ItemEN>
                              <img src={v['trademarkUrl']} alt="상표"/>
                            </ItemEN>
                            <ItemSub>{v['subName']}</ItemSub>
                          </ItemInfo>
                          <Link to={"/cosmetic/product/detail?seq=" + v['seq']}>Chi tiết sản phẩm</Link>
                        </DescInner>
                      </ItemDesc>
                    </li>
                  );
                } else {
                  return (
                    <li
                      key={i}
                      style={{
                        background: 'url(' + v['productUrl'] + ') top 50% left 50% no-repeat',
                      }}
                    >
                      <ItemDesc>
                        <DescInner $reverse="true">
                          {!Common.isEmpty(v['tagName']) &&
                            <ItemUtil>
                              {
                                v['tagName'].split(',').map((item, index) => (
                                  <Util>
                                    <span>{item}</span>
                                  </Util>
                                ))
                              }
                            </ItemUtil>
                          }
                          <ItemInfo>
                            {/* DESC */}
                            <ItemMain>{v['description']}</ItemMain>
                            <ItemKR>{v['productName']}</ItemKR>
                            <ItemEN>
                              <img src={v['trademarkUrl']} alt="상표"/>
                            </ItemEN>
                            <ItemSub>{v['subName']}</ItemSub>
                          </ItemInfo>
                          <Link to={"https://ustvina.com/healthaclean"}>Chi tiết sản phẩm</Link>
                        </DescInner>
                      </ItemDesc>
                    </li>
                  );
                }
              })}
            </Types>
          </Inner>
        </TypeList>
      </ProductSec>
    </>
  );
};

const ProductSec = styled.div``;

const TypeList = styled.div`
  position: relative;
  padding: 95px 0 125px;

  @media only screen and (max-width: 1240px) {
    padding: 30px 16px;
  }
`;

const Inner = styled.div`
  max-width: 1280px;
  margin: 0 auto;

  @media only screen and (max-width: 1240px) {
    max-width: 1240px;
  }
`;

const Types = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 40px 0;

  & > li {
    background-size: cover !important;
  }

  @media only screen and (max-width: 1240px) {
    gap: 10px 0;
  }
`;

const DescInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.$reverse ? 'flex-end' : 'flex-start')};
  text-align: ${props => (props.$reverse ? 'right' : 'left')};

  & a {
    margin-top: 20px;
    display: block;
    width: 200px;
    height: 45px;
    line-height: 45px;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
    background: #484848;
    text-align: center;
  }

  @media only screen and (max-width: 1240px) {
    & a {
      margin-top: 15px;
      width: 135px;
      height: 35px;
      line-height: 35px;
      font-size: 14px;
    }
  }
`;

const ItemUtil = styled.ul`
  display: flex;
  align-items: center;
  gap: 0 12px;
`;

const Util = styled.li`
  padding: 0 15px;
  height: 30px;
  background: #d9d9d9;
  border-radius: 25px;

  & span {
    display: block;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    color: #7d7d7d;
  }

  @media only screen and (max-width: 1240px) {
    padding: 0 8px;
    height: 20px;
    background: #ffffff;

    & span {
      font-size: 12px;
      line-height: 20px;
    }
  }
`;

const ItemDesc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 485px;
  padding: 0 115px;

  @media only screen and (max-width: 1240px) {
    height: 275px;
    padding: 40px 25px;
  }
`;

const ItemInfo = styled.div`
  margin-top: ${props => (props.$data ? '0' : '20px')};

  @media only screen and (max-width: 1240px) {
    margin-top: ${props => (props.$data ? '0' : '10px')};
  }
`;

const ItemMain = styled.p`
  font-weight: 400;
  font-size: 23px;
  color: #747474;

  @media only screen and (max-width: 1240px) {
    font-size: 12px;
  }
`;

const ItemKR = styled.strong`
  margin-top: 15px;
  display: block;
  font-weight: 500;
  font-size: 48px;
  color: #242424;

  & i {
    font-weight: 700;
  }

  @media only screen and (max-width: 1240px) {
    margin-top: 10px;
    font-size: 24px;
  }
`;

const ItemEN = styled.span`
  margin-top: 30px;
  display: block;
  font-weight: 500;
  font-size: 33px;
  color: #747474;

  @media only screen and (max-width: 1240px) {
    margin-top: 1px;
    font-size: 18px;
  }
`;

const ItemSub = styled.p`
  margin-top: 10px;
  display: block;
  font-weight: 400;
  font-size: 18px;
  color: #3f3f3f;

  @media only screen and (max-width: 1240px) {
    margin-top: 7px;
    font-size: 12px;
  }
`;

export default CosmeticType;
