import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const DetailModal = props => {
  const [DetailIndex, setDetail] = useState(props.Hook);

  const tabList = {
    1: <DetailImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/cosmetic/detail01.png"/>,
    2: <DetailImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/cosmetic/detail01.png"/>,
    3: <DetailImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/cosmetic/detail01.png"/>,
  };

  return (
    <>
      <ModalWrap>
        <ModalBox>
          <ModalImg>{tabList[DetailIndex]}</ModalImg>
        </ModalBox>
        <Dimmed onClick={() => props.function()}/>
      </ModalWrap>
    </>
  );
};

const ModalWrap = styled.div`
  z-index: 10;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ModalBox = styled.div`
  z-index: 3;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
`;

const Dimmed = styled.div`
  z-index: 1;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
`;

const ModalImg = styled.div``;

const DetailImg = styled.img.attrs({ alt: '제품 상세 타입 이미지' })``;

export default DetailModal;
