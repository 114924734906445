import React from 'react';
import styled from 'styled-components';

const MainBenefit = () => {
  return (
    <>
      <Container>
        <Inner>
          <Title data-aos="fade-up">
            <strong>Special Benefit</strong>
            <h4>CHỈ CÓ TẠI UST TOWER</h4>
          </Title>
          <BenefitList>
            <li data-aos="fade-up" data-aos-delay="150">
              <BenefitImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/main/bike_ic.svg"/>
              <BenefitDesc>
                <strong>KHÔNG</strong>
                <span>PHÍ GỬI XE</span>
              </BenefitDesc>
            </li>
            <li data-aos="fade-up" data-aos-delay="250">
              <BenefitImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/main/water_tax_ic.svg"/>
              <BenefitDesc>
                <strong>KHÔNG</strong>
                <span>PHÍ NƯỚC SINH HOẠT</span>
              </BenefitDesc>
            </li>
            <li data-aos="fade-up" data-aos-delay="350">
              <BenefitImg src="https://ust-vina.s3.ap-northeast-2.amazonaws.com/main/main_cost_ic.svg"/>
              <BenefitDesc>
                <strong>KHÔNG</strong>
                <span>PHÍ QUẢN LÝ</span>
              </BenefitDesc>
            </li>
          </BenefitList>
        </Inner>
      </Container>
    </>
  );
};

/* 스타일 컴포넌트 */

const Container = styled.div`
  position: relative;
`;

const Inner = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 100px 0 180px;
  background: #f5f5f5;

  @media only screen and (max-width: 1240px) {
    max-width: none;
    padding: 50px 16px 80px;
  }
`;

const Title = styled.div`
  text-align: center;

  & strong {
    font-weight: 500;
    font-size: 26px;
    color: #0067b0;
  }

  & h4 {
    margin-top: 18px;
    font-weight: 700;
    font-size: 44px;
    color: #1a1a1a;
  }

  @media only screen and (max-width: 1240px) {
    & strong {
      font-size: 18px;
    }

    & h4 {
      margin-top: 10px;
      font-size: 28px;
    }
  }

  @media only screen and (max-width: 460px) {
    & strong {
      font-size: 14px;
    }

    & h4 {
      margin-top: 5px;
      font-size: 20px;
    }
  }
`;

const BenefitList = styled.ul`
  margin-top: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 40px;

  & li {
    width: calc(33.33% - 40px);
    height: 350px;
    background: #ffffff;
    border: 1.2px solid #e0e0e0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px 0;
  }

  @media only screen and (max-width: 1240px) {
    margin-top: 40px;
    gap: 0 20px;

    & li {
      width: auto;
      height: 175px;
      flex: 1;
      gap: 10px 0;
      padding: 20px 7px;
      border: 2px solid #e0e0e0;
    }
  }

  @media only screen and (max-width: 720px) {
    gap: 0 10px;

    & li {
      padding: 10px 7px;
    }
  }
`;

const BenefitImg = styled.img.attrs({ alt: '혜택 이미지' })`
  width: auto;

  @media only screen and (max-width: 1240px) {
    width: 75px;
    height: auto;
  }

  @media only screen and (max-width: 360px) {
    width: 48px;
    height: auto;
  }
`;

const BenefitDesc = styled.div`
  text-align: center;

  & span {
    display: block;
    margin-top: 16px;
    font-weight: 700;
    font-size: 26px;
    color: #1a1a1a;
  }

  & strong {
    font-weight: 900;
    font-size: 40px;
    color: #0067b0;
  }

  @media only screen and (max-width: 1240px) {
    & span {
      margin-top: 7px;
      font-size: 16px;
    }

    & strong {
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 720px) {
    & span {
      min-height: 26px;
      font-size: 13px;
    }

    & strong {
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 360px) {
    & span {
      min-height: 26px;
      font-size: 11px;
    }

    & strong {
      font-size: 14px;
    }
  }
`;

export default MainBenefit;
