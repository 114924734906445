import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

/* JS */
import * as Server from '../../asset/js/Server';
import * as Common from '../../asset/js/Common';

/* 슬라이드 - 스와이퍼 */
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay } from 'swiper/modules';
import FloatLink from 'components/layout/float';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';

const ProductDetail = () => {
  const [searchParams] = useSearchParams();
  const [productDetail, setProductDetail] = useState({
    slideY: [],
    slideN: []
  });

  useEffect(() => {
    Server.sendGet(
      'vina/product/img',
      {
        productSeq: searchParams.get('seq'),
      },
      getProductImgList
    ).then();
  }, []);

  const getProductImgList = res => {
    const item = {
      slideY: [],
      slideN: [],
    };

    res['resultList'].forEach(v => {
      if (v['slideYn'] === 'Y') item.slideY.push(v);
      else item.slideN.push(v);
    });

    setProductDetail(item);
  };

  return (
    <>
      <Inner>
        {!Common.isEmpty(productDetail?.slideY) &&
          <Swiper
            modules={[EffectFade, Autoplay]}
            effect="fade"
            autoplay={{
              delay: 6000,
              disableOnInteraction: false,
            }}
            loop={true}
          >
            {productDetail?.slideY?.map((v, i) => (
              <SwiperSlide key={i}>
                <ProdImg src={v['imgUrl']}/>
              </SwiperSlide>
            ))}
          </Swiper>
        }
        {productDetail?.slideN?.map((v, i) => {
          if (v['imgUrl'].includes('mp')) {
            return (
              <DetailVideo autoplay="autoplay" loop="loop" muted="muted" playsinline="playsinline" preload="metadata">
                <source src={v['imgUrl']} type="video/mp4"/>
              </DetailVideo>
            );
          } else {
            return (
              <ProdImg key={i} src={v['imgUrl']}/>
            );
          }
        })}
      </Inner>
      <FloatLink/>
    </>
  );
};

const Inner = styled.div`
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 16px 80px;
  text-align: center;
`;

const ProdImg = styled.img.attrs({ alt: '제품 상세 타입 이미지' })`
  @media only screen and (max-width: 640px) {
    width: 100%;
  }

  width: 100%;
`;

const DetailVideo = styled.video`
  display: block;
  max-width: 905px;
  margin: 0 auto;
  text-align: center;

  @media only screen and (max-width: 640px) {
    width: 100%;
  }
`;

export default ProductDetail;
