import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import AOS from 'aos';
import 'aos/dist/aos.css';

/* JS */
import * as Server from '../asset/js/Server';

/* 섹션 컴포넌트 */
import VisualCosmetic from 'components/cosmetic/visualCosmetic';
import BrandStory from 'components/cosmetic/brandStory';
import CosmeticType from 'components/cosmetic/typeList';
import DetailModal from 'components/cosmetic/detailModal';

const VinaCosmetic = () => {
  const [imgIndex, setModal] = useState(0);
  const [mainItem, setMainItem] = useState({
    banner: [],
    subBanner: [],
    product: [],
  });

  useEffect(() => {
    AOS.init({ duration: 1000 });

    Server.sendGet(
      'vina/main/product',
      {
        bannerType: 'B',
        subBannerType: 'S',
      },
      getProductMainList
    ).then();
  }, []);

  const getProductMainList = res => {
    setMainItem(res['resultObject']);
  };
  const CloseModal = () => {
    setModal(0);
  };
  const HooksModal = HooksIndex => {
    setModal(HooksIndex);
  };

  return (
    <>
      <Content>
        <CosmeticWrap>
          <VisualCosmetic item={mainItem?.banner}/>
          <BrandStory item={mainItem?.subBanner}/>
          <CosmeticType item={mainItem?.product} function={HooksModal}/>
        </CosmeticWrap>
      </Content>
      {imgIndex > 0 && <DetailModal function={CloseModal} Hook={imgIndex}/>}
    </>
  );
};

const Content = styled.div`
  z-index: 1;
  position: relative;
`;

const CosmeticWrap = styled.div`
  position: relative;
`;

export default VinaCosmetic;
